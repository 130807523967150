.webkitHighlight {
  -webkit-tap-highlight-color: transparent;
}

.footer-underline-animation {
  display: inline-block;
  position: relative;
}
.footer-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.footer-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.txt-underline-animation {
  display: inline-block;
  position: relative;
}
.txt-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px;
  bottom: 0;
  left: 0;
  background-color: #9590ff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.txt-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* for custom scroll bar  */
.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.custom-scroll::-moz-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
.custom-scroll:hover::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: rgba(17, 18, 24, 0.03);
  margin: 10px;
}
.custom-scroll:hover::-moz-scrollbar-track {
  border-radius: 3px;
  background-color: rgba(17, 18, 24, 0.03);
  margin: 10px;
}
/* Handle */
.custom-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 99, 168, 0.6);
  border-radius: 3px;
}
.custom-scroll:hover::-moz-scrollbar-thumb {
  background-color: rgba(0, 99, 168, 0.6);
  border-radius: 3px;
}

.custom-display {
  display: -webkit-inline-box;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 14px;
}
.css-uhb5lp {
  border-radius: 14px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  border-radius: 14px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 46px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-1bn53lx {
  height: 46px;
}
.css-1bn53lx {
  height: 46px;
}

.css-hyxlzm {
  color: rgb(207, 205, 205);
}

/* change border-radius for date-picker */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-1yllih9-MuiPaper-root-MuiPickersPopper-paper {
  border-radius: 15px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-a9rw36 {
  border-radius: 15px;
}
.css-lvnr5x-MuiFormControl-root-MuiTextField-root {
  border-radius: 8px;
}
.css-1gkxfbb {
  border-radius: 8px;
}
.MuiFormControl-root.MuiTextField-root.css-ojq1rw-MuiFormControl-root-MuiTextField-root {
  border-radius: 7px;
}
.MuiFormControl-root.MuiTextField-root.css-o7h510 {
  border-radius: 7px;
}

/* change border-radius for menu for mobile device in header */
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 15px;
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-pwxzbm {
  border-radius: 15px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper {
  border-radius: 15px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-736ibl {
  border-radius: 15px;
}

/*  for Skeleton Loader  */

.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-16sv7oo-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}
.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-j1jlda::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}
.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-pulse.css-1jqz1cp-MuiSkeleton-root {
  background-color: #9e9e9e94;
}
.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-pulse.css-86ip4t {
  background-color: #9e9e9e94;
}
.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-pulse.css-gxvogj-MuiSkeleton-root {
  background-color: #9e9e9e94;
}
.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-pulse.css-1j54eq4 {
  background-color: #9e9e9e94;
}

.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-3vbzzq-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}
.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-1c6w3d3::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}

.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-3vbzzq-MuiSkeleton-root {
  background-color: #9e9e9e94;
}
.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-1c6w3d3 {
  background-color: #9e9e9e94;
}

@media all and (max-width: 570px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    width: 91%;
    margin: 0px;
    padding: 5px;
  }
  .css-uhb5lp {
    width: 91%;
    margin: 0px;
    padding: 5px;
  }
}
