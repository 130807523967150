input[type="date"] {
  /* background-color: #000000; */
  /* transform: translate(-50%,-50%); */
  color: #000000;
  font-size: 12px;
  border: 2px gray solid;
  outline: none;
  border-radius: 5px;
  padding: 5px;
  height: "full";
}
::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  /* padding: 5px; */
  cursor: pointer;
  border-radius: 3px;
}
